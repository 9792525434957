<template>
    <div style="display: inline;">
        <a-button v-if="type == 'link'" @click="handleUpgrade" type="link">{{ text }}</a-button>
        <a-menu-item v-if="type == 'menu'" @click="handleUpgrade">购买会员</a-menu-item>
        <a-modal :open="upgradeVisible" title="升级为会员" :footer="null" width="max-content" :onCancel="handleClose" :bodyStyle="{ padding: '0' }">
            <div style="margin-top: 20px; display: flex; flex-direction: row; overflow-x: scroll;">
                <a-card v-for="(plan) in plans" :key="plan.name" :title="null" :bodyStyle="{ width: '195px' }" :style="{ marginLeft: '5px', marginRight: '5px' }" :headStyle="{  textAlign: 'center'}">
                    <div style="text-align: center; font-size: 18px; color: 555;">
                        <span style="font-weight: 700;">{{ plan.name }}</span>
                        <!-- <span style="font-size: 16px;">点</span> -->
                    </div>
                    <div style="text-align: center; margin-bottom: 10px; color: #999;">
                        <span style="font-size: 14px;">￥{{ plan.price }}</span>
                    </div>
                    <div>
                        <p v-for="(label) in plan.labels" :key="label.description" style="margin-top: 2px;">
                            <CheckOutlined :style="{ color: 'green' }" v-if="label.available" />
                            <StopOutlined :style="{ color: 'red' }" v-else />
                            <span style="margin-left: 5px">{{ label.description }}</span>
                        </p>
                        <div style="text-align: center; margin-top: 20px;">
                            <a-popover v-if="plan.price != 0" trigger="click">
                                <template #content>
                                    <div style="text-align: center;">
                                        <span>请扫描二维码购买券码</span>
                                        <!-- <a-qrcode :value="plan.qrcode" :style="{ marginTop: '10px' }" /> -->
                                        <img :src="plan.qrcode" style="margin-top: 10px; display: block; height: 200px; width: 200px;" />
                                    </div>
                                </template>
                                <a-button v-if="plan.price != 0" @click="showQrcode(plan)" type="primary" class="input">立即购买</a-button>
                            </a-popover>
                        </div>
                    </div>
                </a-card>
                <div style="height: 0; clear: both;"></div>
            </div>
            <div style="margin-top: 10px; color: #999;">
                <span v-if="isMobile" style="display: block;">* 滑动查看全部计划</span>
                <!-- <span style="display: block;">* 所有点数购买后永久有效</span>
                <span style="display: block;">* 100点约为 20 ~ 30次 GPT4对话</span> -->
            </div>
            <hr style="margin-top: 10px; margin-bottom: 20px; height: 1px; border: none; background-color: #f0f0f0;" />
            <div style="text-align: center;">
                <a-input v-model:value="inputCode" :style="{ float: 'left', width: isMobile ? '150px' : '180px' }" class="input" :placeholder="isMobile ? '请在此输入券码' : '购买后请在此输入券码'" />
                <a-button @click="verifyCode" :disabled="verifying" type="primary" class="input" :style="{ float: 'left', marginLeft: '10px' }">验证</a-button>
                <a-button @click="handleClose" :style="{ float: 'right' }" class="input">关闭</a-button>
                <div style="height: 0; clear: both;"></div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { CheckOutlined, StopOutlined } from '@ant-design/icons-vue'
import { verifyCode } from '@/api/member'
import { message } from 'ant-design-vue'

export default {
    props: {
        type: String,
        text: String
    },
    data() {
        return {
            upgradeVisible: false,
            plans: [
                {
                    id: 0,
                    name: '免费',
                    price: '0',
                    originalPrice: null,
                    qrcode: null,
                    labels: [
                        { available: true, description: 'GPT 4o mini' },
                        { available: true, description: '每天5次对话' },
                    ]
                },
                {
                    id: 1,
                    name: '月度会员',
                    price: '49',
                    originalPrice: 79,
                    qrcode: 'purchase_m.png',
                    labels: [
                        { available: true, description: 'GPT 4o mini' },
                        { available: true, description: 'GPT 4o' },
                        { available: true, description: 'Claude 3.5 Haiku' },
                        { available: true, description: 'Claude 3.5 Sonnet' },
                        { available: true, description: '每天最多240次对话' },
                    ]
                },
                {
                    id: 2,
                    name: '季度会员',
                    price: '129',
                    originalPrice: 199,
                    qrcode: 'purchase_q.png',
                    labels: [
                        { available: true, description: 'GPT 4o mini' },
                        { available: true, description: 'GPT 4o' },
                        { available: true, description: 'Claude 3.5 Haiku' },
                        { available: true, description: 'Claude 3.5 Sonnet' },
                        { available: true, description: '每天最多240次对话' },
                    ]
                },
                {
                    id: 3,
                    name: '年度会员',
                    price: '499',
                    originalPrice: 699,
                    qrcode: 'purchase_y.png',
                    labels: [
                        { available: true, description: 'GPT 4o mini' },
                        { available: true, description: 'GPT 4o' },
                        { available: true, description: 'Claude 3.5 Haiku' },
                        { available: true, description: 'Claude 3.5 Sonnet' },
                        { available: true, description: '每天最多240次对话' },
                    ]
                }
            ],
            inputCode: '',
            isMobile: window.innerWidth <= 768,
            verifying: false
        }
    },
    components: {
        // eslint-disable-next-line vue/no-unused-components
        CheckOutlined, StopOutlined
    },
    methods: {
        handleUpgrade() {
            this.upgradeVisible = true
        },
        handleClose() {
            this.upgradeVisible = false
        },
        showQrcode(plan) {
            console.log(plan)
        },
        async verifyCode() {
            if (!this.inputCode) {
                return
            }
            this.verifying = true
            var token = (await verifyCode(this.inputCode))?.token
            if (token) {
                localStorage.setItem('BearerToken', token)
                this.inputCode = ''
                this.$emit('tokenChanged')
                message.info('验证成功~')
                this.handleClose()
            }
            this.verifying = false
        }
    }
}
</script>

<style scoped>
.input {
    border-radius: 0;
}
</style>